import { HashLink as Link } from "react-router-hash-link";
import { Button } from "./ui/button";
import Icon from "./Icon";

export default function TableOfContents({
  sections,
  activeSection,
  className,
}: {
  sections: string[];
  activeSection: string;
  className?: string;
}) {

  // if (activeSection === "Inicio") return null;

  return (
    <div
      className={`
        ${activeSection === "Inicio" && "translate-x-full opacity-0 duration-300"} bg-gray-50 hover:bg-gray-100 transition-all shadow-lg dark:hover:bg-gray-900 dark:bg-gray-950 m-3 rounded-xl p-1.5 px-3 toc:pb-2 toc:p-4  ` +
        className
      }>
      <div className="flex-col hidden mb-2 gap-y-1 toc:flex">
        {sections.toReversed().map((section, key) => {
          if (section !== "Inicio") {
            // Exclude "Inicio" from rendering
            return (
              <Button
                key={key} // Move key to the outermost element in the map callback
                variant="link"
                asChild
                className={`w-fit 3xl:text-base p-0 h-auto ${
                  activeSection === section ? "text-blue-300 dark:text-blue-500" : ""
                }`}>
                <Link
                  smooth
                  scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                      inline: "center",
                    })
                  }
                  to={`#${section}`}
                  // className={activeSection === section ? "text-blue-600" : ""}
                  >
                  {section}
                </Link>
              </Button>
            );
          }
          return null; // Return null for "Inicio" or any other condition that should not render
        })}
      </div>
      <span className="w-full hidden toc:block h-px bg-gray-200"></span>
      <Button
        variant="link"
        className="mr-auto 3xl:text-base bg-transparent w-full h-auto px-0 py-1  lg:rounded-none"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        <span className="hidden xl:inline mr-auto">Volver Arriba</span>
        <Icon name="arrow" className="size-7 toc:size-5 3xl:size-6 rotate-90" />
      </Button>
    </div>
  );
}
