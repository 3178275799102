// src/lib/tailwindColors.js
module.exports = {

  'green': {
    DEFAULT: '#53C165',
    50: '#F8FCF9',
    100: '#E9F7EB',
    200: '#CBECD0',
    300: '#ADE1B6',
    400: '#8FD79B',
    500: '#71CC80',
    600: '#53C165',
    700: '#3AA24B',
    800: '#2B7838',
    900: '#1D4F25',
    950: '#153B1B'
  },
  'red': {
    DEFAULT: '#A34729',
    50: '#F7E4DE',
    100: '#F3D7CE',
    200: '#EABDAE',
    300: '#E2A28D',
    400: '#DA876C',
    500: '#D26D4C',
    600: '#C45531',
    700: '#A34729',
    800: '#76331E',
    900: '#492012',
    950: '#33160D'
  },
  'blue': {
    DEFAULT: '#1D91E0',
    '50': '#eef9ff',
    '100': '#dcf3ff',
    '200': '#b2e9ff',
    '300': '#6dd9ff',
    '400': '#20c5ff',
    '500': '#00aeff',
    '600': '#008bdf',
    '700': '#006fb4',
    '800': '#005e94',
    '900': '#004b77',
    '950': '#003151',
  },
  'yellow': {
    DEFAULT: '#FFE666',
    50: "#FFF4CC",
    100: "#FFF1B8",
    200: "#FFEA8F",
    300: "#FFE666",
    400: "#FFE138",
    500: "#FCDC14",
    600: "#E6C300",
    700: "#B89900",
    800: "#8A7000",
    900: "#614E00",
    950: "#473800"
    // 50: '#FCFBED',
    // 100: '#FAF7DC',
    // 200: '#F5EFB8',
    // 300: '#EFE695',
    // 400: '#EADE71',
    // 500: '#E3D340',
    // 600: '#CDBB1E',
    // 700: '#9C8F17',
    // 800: '#6B6210',
    // 900: '#3A3509',
    // 950: '#211F05'
  },
  'gray': {
    DEFAULT: '#3d3d3d',
    50: "#f6f6f6",
    100: "#e7e7e7",
    200: "#d1d1d1",
    300: "#b0b0b0",
    400: "#888888",
    500: "#6d6d6d",
    600: "#5d5d5d",
    700: "#4f4f4f",
    800: "#454545",
    900: "#3d3d3d",
    950: "#1f1f1f",
    1000: "#111111",
  },
}